import {Component, Input} from '@angular/core';
import {BandmanagerRestApiEvent, BandmanagerRestApiEventStatusEnum} from "@digitale-menschen/bandmanager-rest-api";
import {CountryFlagComponent} from "../../../ui/country-flag/country-flag.component";
import {DatePipe} from "@angular/common";
import {InfoBadgeComponent} from "../../../ui/info-badge/info-badge.component";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-header-event',
  standalone: true,
  imports: [
    CountryFlagComponent,
    DatePipe,
    InfoBadgeComponent,
    TranslateModule
  ],
  templateUrl: './header-event.component.html',
  styleUrl: './header-event.component.scss'
})
export class HeaderEventComponent {
  @Input({required: true}) headerEvent?: BandmanagerRestApiEvent;
  protected readonly BandmanagerRestApiEventStatusEnum = BandmanagerRestApiEventStatusEnum;
}
