@if (headerEvent) {
<div class="glob-flex glob-flex--center header-event">
    <app-country-flag [countryCode]="headerEvent.country.code" size="m"></app-country-flag>
    <span class="glob-font-12 glob-font-w600">{{ headerEvent.city }}, {{ headerEvent.country.name }}</span>
    @if (headerEvent.eventStatus === BandmanagerRestApiEventStatusEnum.InProgress) {
      <app-info-badge [silver]="true">{{ "contest.live-tag" | translate }}</app-info-badge>
    } @else {
      <span class="glob-font-12 glob-font-w600"> | {{ headerEvent.startAt | date: "dd.MM.YYYY - HH.mm" }}</span>
    }
 </div>
}
