import {Component, inject, Input} from '@angular/core';
import {LibUiTabsComponent, LibUiTabsItemInterface} from "../../../ui/lib-tabs/lib-tabs.component";
import {Store} from "@ngxs/store";
import {AppStateActions} from "../../../../../shared/app-state/app-state.actions";

@Component({
  selector: 'app-header-tabs',
  standalone: true,
  imports: [
    LibUiTabsComponent
  ],
  templateUrl: './header-tabs.component.html',
  styleUrl: './header-tabs.component.scss'
})
export class HeaderTabsComponent {
  @Input({required: true}) tabsItems: LibUiTabsItemInterface[] = [];
  @Input() isNavigationHeader = false;
  @Input() forModal = false;
  private store = inject(Store)

  protected tabChanged(activeTabIndex: number): void {
    this.store.dispatch(new AppStateActions.setHeaderActiveTabIndex(activeTabIndex, this.forModal ? "modal" : "default"));
  }
}
