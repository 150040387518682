@if (header) {
  <div class="app-header"
       [class.app-header--color-navigation]="header.headerType === HeaderTypeEnum.navigation"
       [class.app-header--base-navigation]="header.headerType === HeaderTypeEnum.standardWithNavigation"
       [class.app-header--base-event]="header.contentEvent"
       [class.app-header--base-tabs]="header.tabsItems"
       [class.app-header--base-event-tabs]="header.contentEvent && header.tabsItems"
  >
    @if (header.headerType === HeaderTypeEnum.default || header.headerType === HeaderTypeEnum.standardWithNavigation) {
      <app-header-stats></app-header-stats>
    }
    @if ((header.headerType === HeaderTypeEnum.navigation || header.headerType === HeaderTypeEnum.standardWithNavigation) && header.navigation) {
      <app-header-navigation
        [hasBackButton]="header.navigation.hasBackButton"
        [hasCloseButton]="header.navigation.hasCloseButton"
        [modalDialogRef]="header.navigation.modalDialogRef"
      >
        @if (header.navigation.titleKey) {
          <h1 class="glob-font-24 glob-no-margin">{{ header.navigation.titleKey | translate }}</h1>
        }
      </app-header-navigation>
      @if (header.contentEvent) {
        <app-header-event [headerEvent]="header.contentEvent"></app-header-event>
      }
    }
    @if (header.tabsItems) {
      <app-header-tabs [tabsItems]="header.tabsItems" [isNavigationHeader]="!!header.navigation" [forModal]="forModal"></app-header-tabs>
    }
  </div>
}
