<div class="glob-flex glob-flex--space-between header-navigation">
  <div class="header-navigation__min">
    @if (hasBackButton) {
      <app-button [level]="null" [variant]="'iconOnly'" (click)="goBack()"><img
        src="../../../../../assets/icons/icon-arrow-left-shadow.svg" width="32" height="32" alt=""></app-button>
    }
  </div>
  <div>
    <ng-content></ng-content>
  </div>
  <div class="header-navigation__min">
    @if (hasCloseButton) {
      <app-button [level]="null" [variant]="'iconOnly'" (click)="close()"><img
        src="../../../../../assets/icons/icon-close.svg" width="32" height="32" alt=""></app-button>
      <!-- [disabled]="!canClose" -->
    }
  </div>
</div>
