<div class="lib-ui-tabs">
  <ul>
    @for (item of tabsElements; track item.value; let index = $index) {
      <li class="lib-ui-tabs__item"
          [class.lib-ui-tabs__item--active]="item.active">
        <button (click)="selectTab(index)">{{ item.label }}</button>
      </li>
    }
  </ul>
</div>
