<div class="header-stats glob-flex glob-flex--space-between">
  @if (stats) {
    <div class="header-stats__progress">
      <app-header-progress-bar [percentDone]="statsPercentDone" [rankingStart]="stats.currentSection" [rankingEnd]="stats.nextSection" ></app-header-progress-bar>
    </div>
  }
  <div class="glob-flex">
    @if (myBand) {
      <app-info-badge [badgeType]="BadgeTypeEnum.skill" [rMargin]="true">+{{ myBand.pointsTotal }}</app-info-badge>
    }
    @if (bankAccount) {
      <app-info-badge [badgeType]="BadgeTypeEnum.money">{{ bankAccount.balance }}</app-info-badge>
    }
  </div>
</div>

