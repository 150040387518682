<div class="glob-badge-bg glob-center app-header-progress-bar">
  <div [style.width]="percentDone + '%'" class="app-header-progress-bar__progress">
    <app-double-glow [bgColor]="'turquoise'"></app-double-glow>
  </div>
  @if (rankingStart) {
    <div class="app-header-progress-bar__start-medal">
      <app-medal [ranking]="rankingStart" [small]="true"></app-medal>
    </div>
  }
  @if (rankingEnd) {
    <div class="app-header-progress-bar__end-medal">
      <app-medal [ranking]="rankingEnd" [small]="true"></app-medal>
    </div>
  }
</div>
