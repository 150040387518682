import {Component, Input} from '@angular/core';
import {MedalComponent} from "../../../ui/medal/medal.component";
import {DoubleGlowComponent} from "../../../ui/double-glow/double-glow.component";

@Component({
  selector: 'app-header-progress-bar',
  standalone: true,
    imports: [
        MedalComponent,
        DoubleGlowComponent
    ],
  templateUrl: './header-progress-bar.component.html',
  styleUrl: './header-progress-bar.component.scss'
})
export class HeaderProgressBarComponent {
  @Input() percentDone: number = 0;
  @Input() rankingStart: string = '';
  @Input() rankingEnd: string | undefined = '';
}
