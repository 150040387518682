import {Injectable} from '@angular/core';
import {Action, createSelector, State, StateContext} from '@ngxs/store';
import {AppStateActions} from './app-state.actions';
import {AppStateModel} from "./app-state.model";
import {AppHeaderInterface} from "../../app/models/app-header.interface";
import {HeaderTypeEnum} from "../../app/models/header-type.enum";

@State<AppStateModel>({
  name: 'appState',
  defaults: {
    headers: []
  }
})
@Injectable()
export class AppState {
  static header(headerTarget: "default" | "modal" = "default") {
    return createSelector([AppState], (state: AppStateModel) => {
      const foundResult = AppState.findHeader(state, headerTarget);
      if (state.headers && foundResult.index > -1) {
        return state.headers[foundResult.index];
      } else {
        return null;
      }
    });
  }

  static activeTabIndex(headerTarget: "default" | "modal" = "default") {
    return createSelector([AppState], (state: AppStateModel) => {
      const foundResult = AppState.findHeader(state, headerTarget);
      if (state.headers && foundResult.index > -1) {
        return (state.headers[foundResult.index].activeTabIndex !== undefined) ? <number>state.headers[foundResult.index].activeTabIndex : -1;
      } else {
        return -1;
      }
    });
  }

  @Action(AppStateActions.setHeader)
  setHeader(ctx: StateContext<AppStateModel>, payload: AppStateActions.setHeader) {
    const state = ctx.getState();
    const newHeader = {
      headerTarget: payload.headerTarget,
      headerType: payload.headerType,
      contentEvent: payload.headerEvent,
      navigation: payload.headerNavigation
    }
    const foundResult = AppState.findHeader(state, payload.headerTarget);
    if (foundResult.index > -1) {
      foundResult.headers[foundResult.index] = newHeader;
    } else {
      foundResult.headers.push(newHeader);
    }
    ctx.patchState({
      headers: foundResult.headers
    })
  }

  @Action(AppStateActions.setTitleKey)
  setTitleKey(ctx: StateContext<AppStateModel>, payload: AppStateActions.setTitleKey) {
    const state = ctx.getState();
    const foundResult = AppState.findHeader(state, payload.headerTarget);
    if (foundResult.index > -1 && foundResult.headers[foundResult.index].navigation) {
      // @ts-ignore
      foundResult.headers[foundResult.index].navigation.titleKey = payload.titleKey || '';
      ctx.patchState({
        headers: foundResult.headers
      })
    }
  }

  @Action(AppStateActions.setHeaderContentEvent)
  setHeaderContentEvent(ctx: StateContext<AppStateModel>, payload: AppStateActions.setHeaderContentEvent) {
    const state = ctx.getState();
    const foundResult = AppState.findHeader(state, payload.headerTarget);

    if (foundResult.index > -1) {
      foundResult.headers[foundResult.index].headerType = HeaderTypeEnum.navigation;
      foundResult.headers[foundResult.index].contentEvent = payload.headerEvent;
      if ( foundResult.headers[foundResult.index].navigation) {
        // @ts-ignore
        foundResult.headers[foundResult.index].navigation.titleKey = payload.headerEvent?.name || '';
      } else {
        foundResult.headers[foundResult.index].navigation = {hasCloseButton: true, hasBackButton: true, titleKey: payload.headerEvent?.name || ''};
      }
      ctx.patchState({
        headers: foundResult.headers
      })
    }
  }

  @Action(AppStateActions.setHeaderTabs)
  setHeaderTabs(ctx: StateContext<AppStateModel>, payload: AppStateActions.setHeaderTabs): void {
    const state = ctx.getState();
    const foundResult = AppState.findHeader(state, payload.headerTarget);
    if (foundResult.index > -1) {
      foundResult.headers[foundResult.index].tabsItems = payload.tabsItems;
      foundResult.headers[foundResult.index].activeTabIndex = payload.tabsItems.findIndex(item => item.active);
      ctx.patchState({
        headers: foundResult.headers
      })
    }
  }

  @Action(AppStateActions.setHeaderActiveTabIndex)
  setHeaderActiveTabIndex(ctx: StateContext<AppStateModel>, payload: AppStateActions.setHeaderActiveTabIndex): void {
    const state = ctx.getState();
    const foundResult = AppState.findHeader(state, payload.headerTarget);
    if (foundResult.index > -1) {
      foundResult.headers[foundResult.index].activeTabIndex = payload.activeTabIndex;
      ctx.patchState({
        headers: foundResult.headers
      })
    }
  }

  @Action(AppStateActions.removeHeader)
  removeHeader(ctx: StateContext<AppStateModel>, payload: AppStateActions.removeHeader) {
    const state = ctx.getState();
    const foundResult = AppState.findHeader(state, payload.headerTarget);
    if (foundResult.index > -1) {
      foundResult.headers.splice(foundResult.index, 1);
      ctx.patchState({headers: foundResult.headers})
    }
  }

  static findHeader(state: AppStateModel, headerTarget: string): { headers: AppHeaderInterface[], index: number } {
    if (state.headers && state.headers.length > 0) {
      let foundIndex = state.headers.findIndex(header => header.headerTarget === headerTarget);
      if (foundIndex !== -1) {
        return {
          headers: [...state.headers],
          index: foundIndex
        };
      }
    }
    return {headers: state.headers || [], index: -1};
  }
}
