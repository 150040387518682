import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';

export interface LibUiTabsItemInterface {
  value: string;
  label: string;
  active?: boolean;
}

@Component({
  selector: 'lib-ui-tabs',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './lib-tabs.component.html',
  styleUrl: './lib-tabs.component.scss',
})
export class LibUiTabsComponent implements OnChanges {
  /**
   * List of tabs
   */
  @Input({required: true})
  public tabsElements: LibUiTabsItemInterface[] = [];

  /**
   * Set the active tab index
   */
  @Input()
  public setActiveTab = 0;

  /**
   * Emit event when tab changed, send the index of the new active tab
   * @private
   */
  @Output()
  private tabItemClicked = new EventEmitter<number>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['setActiveTab'] && !changes['setActiveTab'].isFirstChange() &&
      changes['setActiveTab'].currentValue !== changes['setActiveTab'].previousValue) {
      this.selectTabByIndex(changes['setActiveTab'].currentValue);
    }
  }

  /**
   *
   * @param index
   * @protected
   */
  protected selectTab(index: number): void {
    this.selectTabByIndex(index);
    this.tabItemClicked.emit(index);
  }

  private selectTabByIndex(index: number): void {
    this.tabsElements.forEach((element) => {
      element.active = false;
    });
    this.tabsElements[index].active = true;
  }
}
