import {Component, inject, Input, OnDestroy} from '@angular/core';
import {CommonModule} from "@angular/common";
import {Location} from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {ButtonComponent} from "../../../ui/button/button.component";
import {DialogRef} from "@angular/cdk/dialog";
import {Subscription} from "rxjs";
import {ModalService} from "../../../../services/modal/modal.service";

@Component({
  selector: 'app-header-navigation',
  standalone: true,
  imports: [CommonModule, TranslateModule, ButtonComponent],
  templateUrl: './header-navigation.component.html',
  styleUrl: './header-navigation.component.scss'
})
export class HeaderNavigationComponent implements OnDestroy {
  @Input() hasBackButton = false;
  @Input() hasCloseButton = false;
  @Input() canClose = false;
  @Input() modalDialogRef?: DialogRef<string>;
  private location = inject(Location);

  constructor() {
    window.addEventListener('popstate', this.popHandler);
  }

  ngOnDestroy(): void {
    window.removeEventListener('popstate', this.popHandler);
  }

  private popHandler = (event: Event) => {
    if (this.modalDialogRef) {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.modalDialogRef.close();
    }
  }

  protected goBack(): void {
    if (this.modalDialogRef) {
      this.modalDialogRef.close();
      return;
    }
    this.location.back();
  }

  protected close(): void {
    if (this.modalDialogRef) {
      this.modalDialogRef.close();
      return;
    }
    this.location.back();
  }
}
