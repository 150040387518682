import {Component, inject} from '@angular/core';
import {HeaderProgressBarComponent} from "../header-progress-bar/header-progress-bar.component";
import {InfoBadgeComponent} from "../../../ui/info-badge/info-badge.component";
import {
  BandmanagerRestApiBand,
  BandmanagerRestApiBandStatsDto,
  BandmanagerRestApiBankaccount
} from "@digitale-menschen/bandmanager-rest-api";
import {Store} from "@ngxs/store";
import {BandState} from "../../../../../shared/band-state/band.state";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {TranslateModule} from "@ngx-translate/core";
import {BadgeTypeEnum} from "../../../../models/badge-type.enum";

@Component({
  selector: 'app-header-stats',
  standalone: true,
  imports: [
    HeaderProgressBarComponent,
    InfoBadgeComponent,
    TranslateModule,
  ],
  templateUrl: './header-stats.component.html',
  styleUrl: './header-stats.component.scss'
})
export class HeaderStatsComponent {
  protected readonly BadgeTypeEnum = BadgeTypeEnum;
  protected statsPercentDone = 0;
  protected myBand: BandmanagerRestApiBand | null = null;
  protected bankAccount: BandmanagerRestApiBankaccount | null = null;
  protected stats: BandmanagerRestApiBandStatsDto | null = null;
  private store = inject(Store);

  constructor() {
    this.store.select(BandState.stats).pipe(takeUntilDestroyed()).subscribe((stats) => {
      this.stats = stats;
      this.statsPercentDone = this.store.selectSnapshot(BandState.statsPercentDone);
    });
    this.store.select(BandState.band).pipe(takeUntilDestroyed()).subscribe((band) => {
      this.myBand = band;
    });
    this.store.select(BandState.bankAccount).pipe(takeUntilDestroyed()).subscribe((bankAccount) => {
      this.bankAccount = bankAccount;
    });
  }
}
