import {Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {Store} from "@ngxs/store";
import {AppState} from "../../../../../shared/app-state/app.state";
import {AppHeaderInterface} from "../../../../models/app-header.interface";
import {HeaderStatsComponent} from "../header-stats/header-stats.component";
import {HeaderNavigationComponent} from "../header-navigation/header-navigation.component";
import {BandmanagerRestApiEventStatusEnum} from "@digitale-menschen/bandmanager-rest-api";
import {HeaderEventComponent} from "../header-event/header-event.component";
import {HeaderTabsComponent} from "../header-tabs/header-tabs.component";
import {Subscription} from "rxjs";
import {HeaderTypeEnum} from "../../../../models/header-type.enum";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    HeaderStatsComponent,
    HeaderNavigationComponent,
    HeaderEventComponent,
    HeaderTabsComponent,
    TranslateModule
  ],
  templateUrl: './app-header.component.html',
  styleUrl: './app-header.component.scss'
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  @Input() forModal = false;
  protected header: AppHeaderInterface | null = null;
  protected readonly BandmanagerRestApiEventStatusEnum = BandmanagerRestApiEventStatusEnum;
  private store = inject(Store);
  private storeListener: Subscription = new Subscription();
    protected readonly HeaderTypeEnum = HeaderTypeEnum;

  ngOnInit() {
    this.storeListener = this.store.select(AppState.header(this.forModal ? "modal" : "default")).subscribe((header) => {
      this.header = header;
    });
  }

  ngOnDestroy() {
    this.storeListener.unsubscribe();
  }
}
